import React, { FC } from 'react';
import _ from 'lodash';
import TopSectionItem from './TopSectionItem';
import config from '../../config';
//import { getLocationSrv } from '@grafana/runtime';

const TopSection: FC<any> = () => {
  const navTree = _.cloneDeep(config.bootData.navTree);
  const dashboardItem = _.find(navTree, { id: 'dashboards' });
  if (navTree && navTree.length > 0 && dashboardItem) {
    const dashboardItems = dashboardItem.children;
    if (dashboardItems && dashboardItems.length > 1) {
      const newHomeObject = {
        id: 'newHome',
        text: (
          <span>
            Home
            <span
              style={{
                color: '#dd7127',
                fontSize: '7px',
                fontWeight: 'bold',
                position: 'relative',
                textTransform: 'uppercase',
                top: '-3px',
                verticalAlign: 'top',
                letterSpacing: '0.25px',
                padding: '2px',
                marginLeft: '5px',
                background: '#ffefe3',
                borderRadius: '4px',
              }}
            >
              New
            </span>
          </span>
        ),
        icon: 'gicon gicon-home2',
        url: '/rai/webapp/home',
        target: '_self',
        hideFromTabs: true,
      };
      dashboardItems.splice(1, 0, newHomeObject);
    }
  }

  const quickLinks = {
    id: 'quick links',
    text: 'Quick Links',
    icon: 'fa fa-fw fa-plus',
    sortWeight: -2100,
    url: '#',
    children: [
      // {
      //   icon: 'fa fa-location-arrow',
      //   id: 'places',
      //   subTitle: 'Navigate to line',
      //   text: 'Line',
      //   url: '/d/place',
      // },
      {
        icon: 'fa fa-calendar',
        id: 'line-management',
        subTitle: 'Navigate to Line Management',
        text: (
          <span>
            Floor Management
            <span
              style={{
                color: '#dd7127',
                fontSize: '7px',
                fontWeight: 'bold',
                position: 'relative',
                textTransform: 'uppercase',
                top: '-3px',
                verticalAlign: 'top',
                letterSpacing: '0.25px',
                padding: '2px',
                marginLeft: '5px',
                background: '#ffefe3',
                borderRadius: '4px',
              }}
            >
              Beta
            </span>
          </span>
        ),
        url: '/rai/webapp/daily-sheet',
        target: '_self',
      },
      {
        icon: 'fa fa-dashboard',
        id: 'quick-dashboard',
        subTitle: 'Navigate to dashboard',
        text: 'Dashboard',
        url: '/d/dashboard',
      },
      {
        icon: 'fa fa-video-camera',
        id: 'video',
        subTitle: 'Navigate to video',
        text: 'Video',
        url: '/rai/webapp/recap?timelineMode=1-day&hourTag=true&layoutOrder=timeline-tags-camera&speed=4',
        target: '_self',
      },
      {
        icon: 'fa fa-archive',
        id: 'places',
        subTitle: 'Navigate to product',
        text: 'Product',
        url: '/d/product',
        target: '_self',
      },
      {
        icon: 'fa fa-flask',
        id: 'kaizen',
        subTitle: 'Navigate to kaizen',
        text: 'Experiments',
        url: '/rai/webapp/kaizens',
        target: '_self',
      },
      {
        icon: 'fa fa-clock-o',
        id: 'time-studies',
        subTitle: 'Navigate to Time Studies',
        text: 'Time Studies',
        url: '/rai/webapp/time-study',
        target: '_self',
      },
      {
        icon: 'lightbulb',
        id: 'kaizen-ideas',
        subTitle: 'Navigate to Kaizen Ideas',
        text: 'Ideas',
        url: '/rai/webapp/kaizen-ideas',
        target: '_self',
      },
      {
        icon: 'fa fa-align-justify',
        id: 'training',
        subTitle: 'Navigate to Training',
        text: 'Training',
        url: '/rai/webapp/zen/lesson-vault',
        target: '_self',
      },
      {
        icon: 'fa fa-cog',
        id: 'configuration',
        subTitle: 'Navigate to Configuration',
        text: 'Configuration',
        url: '/rai/webapp/customer',
        target: '_self',
      },
      {
        icon: 'oreo',
        id: 'oreo',
        subTitle: 'Navigate to Oreo',
        text: (
          <span>
            Oreo Chat
            <span
              style={{
                color: '#dd7127',
                fontSize: '7px',
                fontWeight: 'bold',
                position: 'relative',
                textTransform: 'uppercase',
                top: '-3px',
                verticalAlign: 'top',
                letterSpacing: '0.25px',
                padding: '2px',
                marginLeft: '5px',
                background: '#ffefe3',
                borderRadius: '4px',
              }}
            >
              Beta
            </span>
          </span>
        ),
        url: '/rai/webapp/oreo',
        target: '_self',
      },
      {
        icon: 'fa fa-bell',
        id: 'notifications',
        subTitle: 'Navigate to Notifications',
        text: 'Notifications',
        url: '/rai/webapp/notifications',
        target: '_self',
      },
      // {
      //   icon: 'fa fa-user',
      //   id: 'people',
      //   subTitle: 'Navigate to people',
      //   text: 'People',
      //   url: '/d/people',
      // },
      // {
      //   icon: 'fa fa-industry',
      //   id: 'event',
      //   subTitle: 'Navigate to events',
      //   text: 'Events',
      //   url: '/d/events',
      // },
      // {
      //   icon: 'fa fa-camera',
      //   id: 'camera',
      //   subTitle: 'Navigate to camera',
      //   text: 'Camera',
      //   url: '/d/camera',
      // },
      {
        icon: 'fa fa-life-ring',
        id: 'help',
        subTitle: 'Navigate to help',
        text: 'Help',
        url: '/rai/webapp/help',
        target: '_self',
      },
      {
        icon: 'fa fa-life-ring',
        id: 'Tutorials',
        subTitle: 'Open Tutorial modal',
        text: 'Tutorials',
        url: '#',
        onClick: () => {
          const newPath = new URL(location.href);
          newPath.searchParams.append('is_tutorial', 'true');
          history.pushState(
            {
              path: newPath.toString(),
            },
            '',
            newPath.toString()
          );
          location.reload();
        },
      },
      // {
      //   icon: 'fa fa-bolt',
      //   id: 'Courses',
      //   subTitle: 'Open Course modal',
      //   text: 'Courses',
      //   url: '/?orgId=1&is_course=true',
      //   onClick: () => {
      //     location.reload();
      //   },
      // },
    ],
  };
  navTree.unshift(quickLinks);

  let obj_index = -1;
  const home_obj = navTree.filter((item: any, index: number) => {
    if (item['id'] === 'dashboards') {
      obj_index = index;
    }
    return item['id'] === 'dashboards';
  });

  if (home_obj.length > 0) {
    navTree.splice(obj_index, 1);
    navTree.unshift(home_obj[0]);
  }

  const mainLinks = _.filter(navTree, item => !item.hideFromMenu);
  // const searchLink = {
  //   text: 'Search',
  //   icon: 'search',
  // };
  //
  // const onOpenSearch = () => {
  //   getLocationSrv().update({ query: { search: 'open' }, partial: true });
  // };

  return (
    <div className="sidemenu__top">
      {/*<TopSectionItem link={searchLink} onClick={onOpenSearch} />*/}
      {mainLinks.map((link, index) => {
        return <TopSectionItem link={link} key={`${link.id}-${index}`} />;
      })}
    </div>
  );
};

export default TopSection;
