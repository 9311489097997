import React, { FC, useEffect, useState } from 'react';
import { cx } from 'emotion';
import { useStyles } from '@grafana/ui';
import { Branding } from '../Branding/Branding';
import { getLoginStyles } from './LoginLayout';

export const Carousal: FC = ({}) => {
  const loginStyles = useStyles(getLoginStyles);

  const images = ['public/img/carousal/carousal-img-1.png', 'public/img/carousal/carousal-img-3.png'];

  const bottomIconObject = [
    {
      img_src: 'public/img/login/login-bottom-icons/lineManagement.svg',
      text: 'Floor Management',
    },
    {
      img_src: 'public/img/login/login-bottom-icons/timeStudy.svg',
      text: 'Time Studies',
    },
    {
      img_src: 'public/img/login/login-bottom-icons/experiments.svg',
      text: 'Experiments',
    },
    {
      img_src: 'public/img/login/login-bottom-icons/training.svg',
      text: 'Training',
    },
    {
      img_src: 'public/img/login/login-bottom-icons/new-oreo-light.svg',
      text: 'Oreo',
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex(prevIndex => (prevIndex + 1) % images.length);
    }, 5000);

    return () => clearInterval(intervalId);
  }, [currentIndex]);

  const transitionEnd = () => {
    if (currentIndex === images.length) {
      setCurrentIndex(images.length - 1);
    }
  };

  return (
    <div className={cx(Branding.HexagonalBackground())}>
      <div className="login-image-bg-top">
        <Branding.LoginLogo className={loginStyles.loginLogo} />
      </div>
      <div className="login-image-fg">
        <div
          className="carousel-container"
          style={{
            transform: `translateX(-${currentIndex * 100}%)`,
            transition: 'transform 0.5s ease-in-out',
          }}
          onTransitionEnd={transitionEnd}
        >
          {images.map((src, index) => (
            <div className="carousel-item" key={index}>
              <img className="carousel-img" src={src} alt={`Login Image ${index + 1}`} />
            </div>
          ))}
        </div>
        {images.length > 1 && (
          <div className="carousel-indicator">
            {images.map((_, index) => (
              <span
                key={index}
                className={index === currentIndex ? 'carousel-dash carousel-dash-active' : 'carousel-dash'}
                onClick={() => setCurrentIndex(index)}
              />
            ))}
          </div>
        )}
      </div>
      <div className="login-bottom">
        {bottomIconObject.map((obj: any, index: number) => {
          return (
            <div key={index} className="login-bottom-container">
              <img src={obj.img_src} />
              <span className="login-bottom-container-text">{obj.text}</span>
            </div>
          );
        })}
        <div></div>
      </div>
    </div>
  );
};
